import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, Subject } from "rxjs";
import { finalize } from "rxjs/operators";
import { DashboardService } from "./dashboard.service";
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';
import Swal from 'sweetalert2'

// import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';

@Injectable({
    providedIn: 'root'
})
export class InterceptorService implements HttpInterceptor{

    url:string = '';

    constructor(private dashboardService: DashboardService, private readonly router: Router) {
        
    }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        this.url = this.router.url;
        // console.log("this.url", this.url);
/*
        this.dashboardService.show();
*/
        const token = localStorage.getItem('token');

        // console.log(`Token === ${token}`)
        // console.log(req.url)


        if( token ) {
            
            const cloned = req.clone({
                headers: req.headers.set("Authorization",
                    "Bearer " + token)
            });

            return next.handle( cloned ).pipe(
                finalize( () => {

                    // console.log("este", this.url, this.url.includes('dashboard/ranking'));
                    // if ( this.url.includes('dashboard/ranking')) {

                    // } else {
                    //     if ( req.url !== `${environment.apiUrl}/checkToken` ) {

                    //         //aca va el token
                    //         this.dashboardService.checkToken().subscribe({
                    //             next: (res) =>{
                    //                 if(res){
                    //                     const {data} = res
                    //                     // console.log("DATA:", data)
                    //                     if ( parseInt(data.expiry_time) <= 0 && data.valid === true ) {
                    //                         console.log("localStorage:", localStorage);
                    //                         localStorage.removeItem('isLoggedin');
                    //                         console.log("localStorage:", localStorage);
                    //                         localStorage.removeItem('token')
                    //                         console.log("localStorage:", localStorage);
                    //                         Swal.fire({
                    //                             title: 'La sesión ha expirado debe ingresar nuevamente.',
                    //                             showDenyButton: false,
                    //                             showCancelButton: false,
                    //                             confirmButtonText: 'OK',
                    //                         }).then((result) => {
                    //                             // console.log(result)
                    //                             this.router.navigate(['/login']);
                    //                             /* Read more about isConfirmed, isDenied below */
                    //                             // if (result.isConfirmed) {
                    //                             //     Swal.fire('Saved!', '', 'success')
                    //                             // } else if (result.isDenied) {
                    //                             //     Swal.fire('Changes are not saved', '', 'info')
                    //                             // }
                    //                         })
                    //                     } else if ( parseInt(data.expiry_time) <= 0 && data.valid === true ) {
                    //                         console.log("Token por expirado.")
                    //                     }
                    //                 }
                    //             },
                    //             error: (e) => {
                    //                 console.log(e);
                    //             }
                    //         })

                    //     }
                    // }

                    this.dashboardService.hide();
                })
            );

        }else{
            return next.handle( req ).pipe(
                finalize( () => this.dashboardService.hide() )
            );
            // return next.handle(req);
        }

        
    }

}
