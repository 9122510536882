import { Component } from '@angular/core';
import { DashboardService } from 'src/app/core/service/dashboard.service';

@Component({
    selector: 'app-loading',
    templateUrl: './loading.component.html',
    styleUrls: ['./loading.component.scss']
})
export class LoadingComponent {
    isLoading$ = this.dashboardService.isLoading$;
    
    constructor(private dashboardService: DashboardService) { }
}
