import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { ThemePalette } from '@angular/material/core';

@Component({
  selector: 'app-page-not-found',
  templateUrl: './page-not-found.component.html',
  styleUrls: ['./page-not-found.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class PageNotFoundComponent implements OnInit {

  // notFoundForm: FormGroup;

  // Selectores
  colorControl = new FormControl('primary' as ThemePalette);

  constructor(
    // private fb: FormBuilder,
  ) { }

  async initForm() {
    // this.notFoundForm = this.fb.group({
    // });
  };

  async ngOnInit() {
    // await this.initForm();
  }

}
