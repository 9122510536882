import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { DashboardService } from 'src/app/core/service/dashboard.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class LoginComponent implements OnInit {

  public formLogin: FormGroup;
  // site: string = "marcas-ciudadanas";
  site: string = "Marcas Ciudadanas";
  isLogin : boolean = false;
  constructor(
    private router: Router,
    private fb: FormBuilder,
    private dashboardService: DashboardService,
    private route: ActivatedRoute
  ) {}

  async ngOnInit() {

    this.route.queryParamMap.subscribe(async params => {
      const getToken = params.get('token');
      // console.log("Acpa...")
      if ( getToken ){
        // console.log("Aca 2...", getToken);
        await this.onAutoLogin(getToken);
      } else {
        // console.log('acá')
        this.isLogin = true;
        this.formLogin = this.fb.group({
          username: ['',[
            Validators.required
          ]],
          password: ['',[
            Validators.required,
            Validators.minLength(4)
          ]]
        })
      }
    });
  }

  async onAutoLogin( pToken : string ) {
    this.dashboardService.show();
    this.dashboardService.userLogInToken(pToken).subscribe({
      next: (res) => {
        console.log(res)
        if(parseInt(res.status) === 200){
          // console.log('TOKEN AL SITIO', res);
          // Spinner
          this.dashboardService.hide();
          // Token
          this.saveToken(res.data);
          // console.log("RES TOKEN:", res)
          localStorage.setItem('isLoggedin', 'true');
          this.router.navigate(['/dashboard']);
        } 
      },
      error: (e) => {
        this.dashboardService.hide();
      }
    })
  }

  onLogin() {
    if(this.formLogin.valid){
      // localStorage.setItem('isLoggedin', 'true');
      // Spinner
      this.dashboardService.show()

      this.dashboardService.userLogIn(this.site, this.formLogin.value.username, this.formLogin.value.password).subscribe({
        next: (res) => {
          if(parseInt(res.status) === 200){
            // console.log('ENTRE AL SITIO', res);
            // Spinner
            this.dashboardService.hide()
            // Token
            this.saveToken(res.data);
            // console.log("RES TOKEN:", res)
            localStorage.setItem('isLoggedin', 'true');
            this.router.navigate(['/dashboard']);
          } else if(res.status === 404){
            alert(res.msg)
          }
        },
        error: (e) => {
          alert(e)
          this.dashboardService.hide()
        }
      })
     
    }else{
      //ingreso fallido
      alert('Ingreso fallido')
    }
    // localStorage.setItem('isLoggedin', 'true');
    // this.router.navigate(['/dashboard']);


  }

  private saveToken(token: string) {
    localStorage.setItem('token', token);
  }
}
