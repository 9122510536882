<div [ngClass]="this.isLogin ? 'login-page' : 'login-page-redirect'">
  <div class="content px20">
    <div *ngIf="this.isLogin; else elseLogin">
      <form class="login-form" #myForm="ngForm" [formGroup]="formLogin" (ngSubmit)="onLogin()">
        <div class="text-center">
          <div class="login-brand">
            <img src="../../../../assets/images/logommcc-1.png" alt="Logo" />
          </div>
        </div>
        <div fxLayout="row">
          <div fxFlexFill >
            <mat-form-field class="w-100" appearance="fill">
              <input 
                matInput 
                formControlName="username"
                placeholder="Email@example.com"
                required
              >
            </mat-form-field>
            <mat-form-field class="w-100">
              <input 
                matInput
                formControlName="password"
                type="password" 
                placeholder="Contraseña"
                required
              >
            </mat-form-field>
          </div>
        </div>
        <br>
        <div fxLayout="row">
          <div fxFlexFill>
            <button type="submit" mat-raised-button color="primary" class="w-100">Login</button>
          </div>
        </div>
      </form>
    </div>
    <ng-template #elseLogin>
      <div>
        <img src="../../../../assets/images/logommcc-1.png" alt="Logo" />
      </div>
      <div style="height: 100%;margin: 0 0 30px 0;display: flex;justify-content: center;align-items: center;">
        <h1>Redireccionando...</h1>
      </div>
    </ng-template>
  </div>
</div>
