import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { Subject } from 'rxjs';
import { Router } from '@angular/router';

@Injectable({
    providedIn: 'root'
})
export class DashboardService {
    isLoading$ = new Subject<boolean>();
    constructor(
        private http: HttpClient,
        private router: Router
    ) { }

    // getData_dashboard(mes:string, year:string) {
    //     return this.http
    //         .post<any>(`${environment.apiUrl}/api/get-mc-dashboard`, {
    //             "year": year,
    //             "mes": mes
    //         })
    //         .pipe(
    //             map((data) => {
    //                 return data;
    //             })
    //         );
    // }

    // getData_estudio(estudio: string, year: string, mes: string){
    //     return this.http
    //         .post<any>(`${environment.apiUrl}/api/get-fl-detalle-estudio`,{
    //             "estudio": estudio,
    //             "year": year,
    //             "mes": mes 
    //         })
    //         .pipe(
    //             map((data) => {
    //                 return data
    //             })
    //         );
    // }

    userLogIn(site: string, username: string, password: string) {
        return this.http
            .post<any>(`${environment.apiUrl}/login`, {
                "email": username,
                "password": password
            })
            .pipe(
                map((data) => {
                    return data;
                })
            );
    }

    userLogInToken(pToken : string) {
        return this.http
            .post<any>(`${environment.apiUrl}/loginToken`, {
                "tokenSite": pToken
            })
            .pipe(
                map((data) => {
                    return data;
                })
            );
    }
    

    allDataRanking() {
        return this.http
            .post<any>(`${environment.apiUrl}/api/v2/get-marcas-dashboard`, {})
            .pipe().toPromise()
    }

    // allDataRanking() {
    //     return this.http
    //         .post<any>(`${environment.apiUrl}/api/get-marcas-dashboard`, {})
    //         .pipe().toPromise()
    // }

    getAnioMedicion(category) {
        const body = { "tabla": category };
        return this.http
            .post<any>(`${environment.apiUrl}/api/get-medicion`, body)
            .pipe();
    }

    getAnioMedicionSegmento(category, pIdSegmento) {
        const body = { "tabla": category, "id_segmento" : pIdSegmento};
        return this.http
            .post<any>(`${environment.apiUrl}/api/get-medicion-segmento`, body)
            .pipe();
    }

    getSegmentos() {
        return this.http
            .post<any>(`${environment.apiUrl}/api/get-segmentos`, {})
            .pipe();
    }

    // Nombres e id de todas las marcas:
    getMarcas(category) {
        const body = { "tabla": category };
        return this.http
            .post<any>(`${environment.apiUrl}/api/get-marcas`, body)
            .pipe();
    }

    // Ranking por segmentos:
    // getRankingSegmentos(idSegmento: number = null, idMedicion: number = null, idIndustria: string, ranking: number) {

    //     if (!idSegmento || !idMedicion) return

    //     const body = { "id_segmento": idSegmento, "id_medicion": idMedicion };
    //     return this.http
    //         .post<any>(`${environment.apiUrl}/api/get-ranking-segmentos`, body)
    //         .pipe();
    // }

    // Ranking por segmentos:
    getRankingSegmentos(idSegmento: number = null, idMedicion: number = null, idIndustria: string, topLimit: number) {

        if (!idSegmento || !idMedicion) return

        const body = { "id_segmento": idSegmento, "id_medicion": idMedicion, "id_industria": idIndustria, "top_limit": topLimit};
        return this.http
            .post<any>(`${environment.apiUrl}/api/get-ranking-segmentos`, body)
            .pipe();
    }

    // Ranking por segmentos V2 (según categoría):
    getRankingSegmentosV2(idSegmento: number = null, idMedicion: number = null, idIndustria: string, category: string) {

        if (!idSegmento || !idMedicion) return

        const body = { "tabla": category, "id_segmento": idSegmento, "id_medicion": idMedicion, "id_industria": idIndustria };
        return this.http
            .post<any>(`${environment.apiUrl}/api/get-ranking-segmentos-v2`, body)
            .pipe();
    }

    // Ranking gráficos por segmentos:
    getGraficosPorSegmento(idSegmento: number = null) {

        if (!idSegmento) return

        const body = { "id_segmento": idSegmento };
        return this.http
            .post<any>(`${environment.apiUrl}/api/get-graficos-segmentos`, body)
            .pipe().toPromise();
    }

    // Ranking gráficos por segmentos V2 (según categoría):
    getGraficosPorSegmentoV2(idSegmento: number = null, category: string) {

        if (!idSegmento) return

        const body = {  "tabla": category, "id_segmento": idSegmento };
        return this.http
            .post<any>(`${environment.apiUrl}/api/get-graficos-segmentos-v2`, body)
            .pipe().toPromise();
    }

    // Ranking gráficos TODOS los segmentos
    getRankingTodosLosSegmentos() {
        return this.http
            .post<any>(`${environment.apiUrl}/api/get-all-ranking`, {})
            .pipe();
    }

    // getMarcas() {
    //     return this.http
    //         .post<any>(`${environment.apiUrl}/api/get-marcas`, {})
    //         .pipe();
    // }




    // Tablas del home:
    getDataTableWithFilters(idContenido: number, idMedicion: number, idIndustria?: string, topLimit?: number) {

        const body = {
            id_contenido: idContenido,
            id_year: idMedicion,
            id_industria: idIndustria,
            top_limit: topLimit
        }

        return this.http
            .post<any>(`${environment.apiUrl}/api/v1/dashboard/get-top-contenido`, body)
            .pipe();

    }

    // Años
    getOptionsYears(idYear: number) {

        const body = {
            "id_contenido": idYear
        }
        return this.http
            .post<any>(`${environment.apiUrl}/api/v1/filter/get-filter-year`, body)
            .pipe();
    }

    // Contenido: Aporte, Relevancia, Ranking, etc...
    getOptionsContenido() {
        return this.http
            .post<any>(`${environment.apiUrl}/api/get-filter-contenido`, {})
            .pipe();
    }

    // Industrias
    getOptionsIndustrias() {
        return this.http
            .post<any>(`${environment.apiUrl}/api/get-filter-industria`, {})
            .pipe();
    }

    getOptionsIndustriasV2() {
        return this.http
            .post<any>(`${environment.apiUrl}/api/get-filter-industria-v2`, {})
            .pipe();
    }

    checkToken() {
        // console.log(`TOKEN CHECK TOKEN ::: ${token}`);
        return this.http
            .post<any>(`${environment.apiUrl}/checkToken`, {})
            .pipe(
                map((data) => {
                    // console.log(data)
                    return data;
                })
            );
        // console.log(returnToken)
    }


    // Spinner general
    show(): void {
        this.isLoading$.next(true);
    }

    hide(): void {
        this.isLoading$.next(false);
    }

    // Fernando López
    getDashboardIndustrias(pTable: string) {
        const body = { "tabla": pTable };
        return this.http
            .post<any>(`${environment.apiUrl}/api/v1/dashboard/get-industrias`, body)
            .pipe();
    }
    getDashboardMarcas(pTable: string, pIndustria: string) {
        const body = { "tabla": pTable, "industria": pIndustria };
        return this.http
            .post<any>(`${environment.apiUrl}/api/v1/dashboard/get-marcas`, body)
            .pipe();
    }

    getMarcasSegmentos(pTable: string) {
        const body = { "tabla": pTable};
        return this.http
            .post<any>(`${environment.apiUrl}/api/v1/dashboard/get-marcas-segmentos`, body)
            .pipe();
    }
    
    getAllDataMarcas() {
        return this.http
            .post<any>(`${environment.apiUrl}/api/v1/dashboard/get-all-data-marcas`, {})
            .pipe().toPromise()
    }
    getAllDimensiones() {
        return this.http
            .post<any>(`${environment.apiUrl}/api/v1/atributos/get-dimension`, {})
            .pipe().toPromise()
    }
    getAllSubDimensiones(data) {
        return this.http
            .post<any>(`${environment.apiUrl}/api/v1/atributos/get-sub-dimension`, {
                "id_dimension":data
            })
            .pipe().toPromise()
    }
    getYear(idDimension) {
        return this.http
            .post<any>(`${environment.apiUrl}/api/v1/atributos/get-year`, {
                "id_dimension" : idDimension
            }).pipe().toPromise()
    }
    getPromedioDesempeno(idMarca:any,idDimension:any,idMedicion:any) {
        return this.http
            .post<any>(`${environment.apiUrl}/api/v1/atributos/get-promedio-desempeno`, {
                "id_marca" : idMarca
                , "id_dimension" : idDimension,"id_medicion":idMedicion}).pipe().toPromise()
    }

    getPromedioEntorno(idDimension,idMedicion) {
        return this.http
            .post<any>(`${environment.apiUrl}/api/v1/atributos/get-promedio-entorno`, {
                 "id_dimension" : idDimension
                ,"id_medicion":idMedicion
            }).pipe().toPromise()
    }
    getAtributos(idDimension) {
        return this.http
            .post<any>(`${environment.apiUrl}/api/v1/atributos/get-list`, {
                "id_dimension" : idDimension
            }).pipe().toPromise()
    }
    getPromedioGetZoom(idDimension,idSubDimension,idMedicion) {
        return this.http.post<any>(`${environment.apiUrl}/api/v1/atributos/get-zoom`, {"id_dimension" : idDimension, "id_sub_dimension" : idSubDimension,"id_medicion":idMedicion}).pipe().toPromise()
    }
    getMarca(id_marca) {
        return this.http.post<any>(`${environment.apiUrl}/api/v1/one-page/get-marca`, {"id_marca" : id_marca, "id_medicion" : 11}).pipe().toPromise()
    }
    getMarcasAtributos(id_medicion) {
        return this.http.post<any>(`${environment.apiUrl}/api/v1/atributos/get-marcas`, {"id_medicion" : id_medicion}).pipe().toPromise()
    }
    getRankingAtributos(value) {
        return this.http.post<any>(`${environment.apiUrl}/api/v1/atributos/get-ranking-atributos`, value).pipe().toPromise()
    }
    

}