
  <div class="container">
    <h1>Página no encontrada</h1>
    <h4>No se pudo encontrar la página que solicitaste</h4>
  
    <div>
      <button mat-raised-button color="primary"
        class="example-button-row" [routerLink]="['/dashboard']">Volver al inicio</button>
    </div>
  </div>

